<template>
  <div class="centerwrap">
    <div class="centerbox">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="bread">
        <el-breadcrumb-item :to="{ path: '/order' }"
          >交易列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>提现列表</el-breadcrumb-item>
      </el-breadcrumb>
      <el-table :data="listData" stripe size="small" style="width: 100%">
        <el-table-column prop="money" label="金额（元）"> </el-table-column>

        <el-table-column prop="time" label="日期" >
        </el-table-column>
        <el-table-column prop="statusMsg" label="状态"width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.statusMsg }} </span>

            <el-popover
              v-if="scope.row.status == 3 || scope.row.status == 4"
              placement="top"
              title="详情"
              width="200"
              trigger="click"
              :content="scope.row.detail"
            >
              <el-button slot="reference" type="warning" size="mini" plain>
                详情</el-button
              >
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column prop="Status" label=" " width="90"> </el-table-column>
      </el-table>
      <el-pagination
        background
        v-if="total > pageCount"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :page-size.sync="pageCount"
        :current-page="currentPage"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { getWithdrawRequest } from "@/api/project.js";
export default {
  data() {
    return {
      listData: [],
      currentPage: 1,
      total: "",
      pageCount: 10,
    };
  },
  created() {
    this.getWithdrawRequest();
  },
  methods: {
    showtext(e) {
      e.currentTarget.className = "showtext";
    },
    hidetext(e) {
      e.currentTarget.className = "";
    },
    handleClick(row) {
      console.log(row);
    },
    async getWithdrawRequest(val) {
      try {
        val = val || "1";
        const result = await getWithdrawRequest();
        if (result.status == 200) {
          this.listData = result.data.list;
          this.total = result.data.totalCount;
          this.pageCount = result.data.pageCount;
        }
      } catch (err) {}
    },
     handleCurrentChange(val) {
      this.getOrderRequest(val);
    },
  },
};
</script>
<style  lang="less" scoped>
.mb15 {
  margin-bottom: 15px;
}
.centerwrap {
  position: relative;
  padding: 0 20px;
}
.centerbox {
  width: 100%;
}

.org {
  color: #ed8b02;
}
.green {
  color: #009701;
}
.grey {
  color: #bdbdbd;
}
.topbar {
  background: #e5f4ff;
  border-radius: 4px;
  padding: 0 15px;
  margin: 20px 0;
  font-size: 14px;
  color: #666;
  line-height: 50px;
  .money {
    margin-right: 15px;
    span {
      font-size: 26px;
      color: #000;
    }
  }
  .moneyorg {
    color: #ed8b02;
    font-size: 12px;
    margin: 0 10px;
  }
  .txlist {
    float: right;
  }
}
/deep/.el-breadcrumb {
  margin: 20px 0;
}
.el-button--warning.is-plain{
  padding:2px 5px;
}
.el-pagination {
  text-align: right;
  margin: 20px;
}
</style>